import React, {useEffect, useState} from "react";
import {Box, Container, Grid, Paper} from "@mui/material";
import useAxios from "axios-hooks";

export const GalleryDemo = () => {
    const [{data, loading, error}, refetch] = useAxios('https://davines-photo-api-qcc4npbf6q-ew.a.run.app/photo/approved',{})

    useEffect(() => {
        setInterval(() => refetch(), 5000)
    }, [])

    return (
        <Grid container columns={12} spacing={3} style={{height:'100vh'}}>
            {(!error && data && data.length>=0) && data.slice(0,72).map(p =>
                <Grid item sx={{height:'17.1%'}} xs={1}>
                    <img style={{width:'100%', height:'100%', objectFit:'cover'}} src={"https://storage.googleapis.com/photo-davines-23/"+p.filename}/>
                </Grid>
            )}
        </Grid>
    )
}
