import React, {useEffect, useState} from "react";
import {Box, Container, Grid, Paper} from "@mui/material";
import useAxios from "axios-hooks";

export const GalleryStatic = () => {
    const [{data, loading, error}, refetch] = useAxios('https://davines-photo-api-qcc4npbf6q-ew.a.run.app/photo/approved',{})

    return (
        <Grid container columns={22} justifyContent={'center'}>
            {(!error && data && data.length>=0) && data.slice(0,132).map(p =>
                <Grid item sx={{height:'180px', p:'5px'}} xs={1}>
                    <Box sx={{width:'100%', height:'100%'}}>
                        <img style={{width:'100%', height:'100%', objectFit:'cover'}} src={"https://storage.googleapis.com/photo-davines-july-23/"+p.filename}/>
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}
