import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {edisonTheme} from "./theme/edisonTheme";
import {Route, Routes} from "react-router-dom";
import {GalleryOriginal} from "./pages/GalleryOriginal";
import {GalleryDemo} from "./pages/GalleryDemo";
import {GalleryStatic} from "./pages/GalleryStatic";


function App() {
    return (
        <ThemeProvider theme={edisonTheme}>
            <CssBaseline/>
            <div className="App" style={{color: '#212e6d', fontWeight:'600'}}>
                <Routes>
                    <Route path={'/'} element={<GalleryOriginal/>}/>
                    <Route path={'/demo'} element={<GalleryDemo/>}/>
                    <Route path={'/static'} element={<GalleryStatic/>}/>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
